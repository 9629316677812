@import url("https://fonts .goog leapis. com/css2?family=Sora&display=swap");
@font-face {
  font-family: Strawford;
  font-style: normal;
  src: url("../public/fonts/strawford-light-webfont.woff2") format("woff2");
  font-display: swap;
}

* {
  font-family: Strawford, "Sora", sans-serif;
  box-sizing: border-box;
  margin: auto;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}
